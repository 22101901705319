<template>
 <div style='width: 100%; height: 100%; position: relative;'>

	<div class='transition uploadArea' style='float: top; width: 100%; position: relative;' id='asRun' > 
		<div v-if="data.lines">
			<div style='width: 100%; height: calc(28px); float: top; overflow-y: scroll; position: relative;'>
				<div v-for="(col,idx2) in columns" :key="'h-'+idx2" class='floatLeft ' :style='getColumnStyle(col)'>
				    <div class='header spotDetail' :style='getSpotDetailStyle(col,col)'>
					{{col.name}}
					</div>
				</div>
			</div> 
			<div style='width: 100%; height: calc(80vH); overflow-y: scroll;position: relative;'>
				<div v-for="(line,idx) in data.lines" :key="'L'+idx" >
					<div :id="'L'+idx"
					     @mouseenter="$emit('findSpot', line); hoverLine('L'+idx)"
						 @mouseleave="leaveLine('L'+idx)"
						 @click="$emit('markSpot', line)"
						 style='background-color: #ffc !important;'
						 :mat="line.materialAsString"
						 :reco="line.reconcileString" 
					>
						<div v-for="(col,idx2) in columns" :key="'col-'+idx+'.'+idx2" class='floatLeft ' :style='getColumnStyle(col, line)'>
							<div v-if="col.match" class='spotDetail transitionFast'>
								<mdicon style='background-color: green; color: green;' color='green' 
								v-if='data.meta.matched && data.meta.matched.includes(line.lineNo)' :width="16" name="check" />
							</div>
									        
					       <div v-else class='spotDetail transitionFast' :style='getSpotDetailStyle(col, line)' >
					       		{{ print(line, col) }}
					       	</div>
					    </div> 
						
					</div>
				</div>
			</div>
		 </div>

	</div>

 </div>
</template>	
<script>
import {HTTP, HTTPMP, asrAPI, opsAPI, bngAPI, fwAPI, invAPI, setReload, showError, myLocale} from '@/variables.js';
import {store, restore} from '@/runDown.js';
import {printTimeOpt, printTimeHHMM, printTime } from '@/basicTimeFN.js';

import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';

import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
var timers = [];
export default {
  name: 'GFWASRUN_RUNDOWN',
   
  components : {

  },
  props: {
    data: Object,
    lookForSpot: {}
  },
  data () {
    return {
       widthCalculated: 100,
       counter: 0,
       columns: [
            
            {name: "Day", show: "txDate", date:true, w: 7, align: 'C' },
	        {name: "Time", show: "startTimeMS", time:true,  w: 7, align: 'C' },
	        {name: "Dur", show: "durationMS", time:true, w: 7, align: 'C' },
	        
	        {name: "Material", show: "materialAsString", w: 10, align: 'C' },
	        {name: "Title", show: "title", weekday: true, w: 30, align: 'C' },
	        {name: "Key", show: "reconcileString",  w: 5, align: 'C' },
	        {name: "Device", show: "deviceAsString",  w: 5, align: 'C' },
	        {name: "Type", show: "typeAsString",  w: 2, align: 'C' },
	        {name: "M", show: "match", match:true, w: 4, align: 'C' }
	        //,
	        
	        //{name: "CH", show: "chAsString",  w: 0, align: 'C' },
	       
	        //{name: "Status", show: "statusAsString", w: 0, align: 'C' }
	        //,
	        //{name: "Valid", show: "valid", boolean:true, w: 3, align: 'C' }
       ]
      }
  },
  methods: {
   
    print(spot, col)
     {
        //return spot[ col.show ];
     	let val = spot[ col.show ];
     	if ( col.boolean )
     	{
     		if ( val )
     		{
     			return "X";
     		}
     		return "";
     	}
     	if ( col.date )
     	{
     		let arr = val.split('-');
     		return arr[2]+"-"+arr[1]+"-"+arr[0];
     	}
     	if ( col.match )
     	{
     	
     		return this.data.meta.matched && this.data.meta.matched.includes(spot.lineNo)?"X":"";
     	}
     	if ( col.time )
     	{
     		return printTimeOpt(val/1000,":",true);
     	}
     	return val;
     },
    getColumnStyle(col, line)
     {
        return "width: " + (parseInt(col.w)/this.widthCalculated*100) +"%;"
     }, 
     getSpotDetailStyle(col, line)
     {
     	let textAlign = 'justify-content: center;';
        if ( col.align == 'L' )
        {
        	textAlign = 'justify-content: left;';
        }
        else if ( col.align == 'R' )
        {
        	textAlign = 'justify-content: right;';
        }
        if ( this.loadingActive )
        {
        	return textAlign+"color: #ccc; font-size:"+this.fontSize+"px";
        }
        if ( line.valid )
        {
        	textAlign += 'font-weight: bold;';
        }
        else
        {
        	textAlign += 'color: #aaa;';
        }
     	return textAlign+"font-size:"+this.fontSize+"px";
     },

    hoverLine(line)
    {
    	$('#'+line).children().addClass('reqLineBG2');
    },
    mark(lookForSpot)
    {
    	$("[mat='"+lookForSpot.copyHouseNumber+"']").children().addClass('reqLineSearch');
    },
    match(lookForSpot)
    {
    	$("[reco='"+lookForSpot.id+"']").children().removeClass('reqLineSearch');
    	$("[reco='"+lookForSpot.id+"']").children().addClass('reqLineSearchMatch');
    },
    unmark()
    {
    	$(".reqLineSearch").removeClass('reqLineSearch');
    	$(".reqLineSearchMatch").removeClass('reqLineSearchMatch');
    },
    leaveLine(line)
    {
    	$('#'+line).children().removeClass('reqLineBG2');
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    },
    
  	calcWidths()
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.columns )
     	{
     		wTot += this.columns[ i].w;
     	}
     	
     	this.widthCalculated = wTot;
	},
  
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  watch: {
  	lookForSpot: function() {
  	    this.unmark();
		this.mark(this.lookForSpot)
		this.match(this.lookForSpot)
  	}
  },
  created() { 
    this.calcWidths();


  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  width: 100% !important; 
  height: 16pt; 
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: transparent;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 82%; 
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.header {
  font-size: 9pt;
  color: #000 !important;
  font-weight: bold;
}
.reqLineSearchMatch {
	cursor: pointer;
    color: #000;
	background-color: #bfb !important;
}
.reqLineSearch {
    cursor: pointer;
    color: #000;
	background-color: #ffc !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
.reqLineBG2 {
    cursor: pointer;
    color: #00a;
	background-color: #fcf !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
</style> 