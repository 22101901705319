<template>
<div>
    
    <div style='float: top; display: flex; width: 99.8% !important;' class='RDHeader' :style='getHeaderStyle()'>
        <div style='display: inline-flex; width: 100% !important;' :style='getHeaderStyle()'>
        	<div style='width:18%'>
	        <InputDatePick :disabled='true' :auto='false' style='font-size: 9pt; width:100px; height:28px;' @change="dateChanged" v-model="date"/>
	        </div>
	        <div style='width:60%'>
	        <vSelect placeholder='choose channel' :disabled='true' :options="stations" style='font-size: 8pt; width:100%' v-model="selectedStation" @input='stationChanged'> 
		    </vSelect>
	    	</div>
	    	<span style='padding-left: 2pt;'>
				<vSelect :options="statusList" style='font-size: 8pt; width: 120px;' v-model="selectedState" :multiple="false" @input="stateChanged()">  
				</vSelect>
			</span>
			
		</div>

    </div>
	
 	<div ref='scrollTable' style='width: 100%; height: calc(82vH); overflow-y: scroll; position: relative; font-size: 12px;'>
	    <div>	    
	    	<div v-for="(grid,idx1) in myData.gridAds" :key="'Grid'+idx1" :class='getSlotClass(grid, selectedPrograms)'>
		 	    <div class="RDOpenProgram">
			 		<div class="RDProgram">
			 		    <span class="RDProgramTime">
			 				{{ printTime2(grid.starttime)}} 
			 				<span v-show='grid.diff' class="RDProgramTime2">
				 				({{ printTime2(grid.starttime+grid.diff)}})
				 			</span>
			 			</span>
			 			
				 		<div class="RDProgramTitle">
				 			{{ grid.detail.name }}
				 		</div>
			 		</div>
		
		 		<div style='position: relative;' v-for="(brk,idx2) in grid.breaks" v-show="brk.show && (showEmptyBreaks || (brk.spots && brk.spots.length))" 
		 		     :key="'AS'+idx1+'.'+idx2">
		 				<div class='RDBreak'>
		 				 
			 				<span class="RDBreakTime">
				 				{{printTime2(brk.space.estStarttime)}}
				 				<span v-show='grid.diff' class="RDBreakTime2">
					 				({{ printTime2(brk.space.estStarttime+grid.diff)}})
					 			</span>
				 			</span>
				 			
					 		<span class="RDBreakTitle">

					 			{{ getBreakCode( brk.space, brk.type) }}
					 		</span>
		 				</div>
						<SimpleSpotList 
						    :ref="getRef(idx1, idx2)"
							@arrangeSpots=arrangeSpots 
							@changeTypePassThrough=changeTypePassThrough
							@moveSpots=moveSpots 
							@copyBreak=copyBreak 
							@moveBreak=moveBreak 
							@runIBO=runIBO
							@deleteSpot=deleteSpot
							@contextmenu=contextmenu
							@removeCopy=removeCopy
							@setPrice=setPrice
							@userUpdated=userUpdated
							@findInLog=findInLog
							:airedStatus=airedStatus
							:notAiredStatus=notAiredStatus
							:name="getRef(idx1,idx2)"
							:identifier=getRawKey(brk.brk)
							:update=update
							:updatedSpots=updatedSpots
							:fontSize=fontSize
							:dayId=dayId
							:user=user
							:lookForLine="searchLine"
							:selectedIN=selectedIN
							:spots="brk.spots?brk.spots:[]">
					    </SimpleSpotList>
		 		</div>
		 		</div>
		 	</div>
	
	 	</div>
	 </div>
	 
	 <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
       

         <ContextMenuItem  @clicked="closeCMN(); $refs[contextData.name][0].runInBreakOptimizer(contextData.spot);">
          Auto reconcile all
        </ContextMenuItem>
        <ContextMenuSep/>
         <ContextMenuItem v-if='airedStatus'  @clicked="closeCMN(); changeAllType($event, airedStatus);">
          Mark all as {{airedStatus.name}}
        </ContextMenuItem>
        <ContextMenuItem  v-if='notAiredStatus' @clicked="closeCMN(); changeAllType($event, notAiredStatus);">
          Mark all as {{notAiredStatus.name}}
        </ContextMenuItem>
        <ContextMenuItem  v-if='defaultStatus' @clicked="closeCMN(); changeAllType($event, defaultStatus);">
          Reset all as {{defaultStatus.name}}
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.spot.placementId" @clicked="closeCMN(); openBookMan(contextData.spot)">
         Open Traffic Manager for {{contextData.spot.placementName}}
        </ContextMenuItem>
        <ContextMenuSep/>
       <ContextMenuItem v-if='airedStatus' @clicked="changeType($event, airedStatus, contextData.spot)">
         Mark this spot as {{airedStatus.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if='notAiredStatus' @clicked="changeType($event, notAiredStatus, contextData.spot)">
          Mark this spot as {{notAiredStatus.name}}
        </ContextMenuItem>
 		<ContextMenuItem v-if='defaultStatus' @clicked="changeType($event, defaultStatus, contextData.spot)">
          reset this spot as {{defaultStatus.name}}
        </ContextMenuItem>
        
        
       </template>
    </ContextMenu>
    
</div>
</template> 
<script>
import { HTTP, APIURL, PlayListAPI, myLocale} from '@/variables.js';
import { printTime2 } from '@/basicTimeFN.js';
import { setGoBack } from '@/breadCrumb.js';
import { openView } from '@/utils.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import SimpleSpotList from '@/components/asrun/SimpleSpotList';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFWRunDownView',
  components : {
   SimpleSpotList, vSelect, InputDatePick, ContextMenu, ContextMenuItem, ContextMenuSep
  },
  props: {
    station: Object,
    bookingStatusList: Array,
    stations: Array,
    date: Date,
    lookForLine: Object,
    updateLine: Object,
    fontSize: {type: Number, default: 9},
    showEmptyBreaks: Boolean,
    selectedPrograms: Array,
    selectedStates: Array,
    selectedClients: Array,
    selectedProducts: Array,
    selectedCopies: Array,
    selectedBreaks: Array,
    updatedSpots: Array,
    stateName: String,
    timezone: Object,
    update: Number,
    dayId: String,
    user: Object,
    selectedIN: String,
    data: Object
  },
  data () {
    return {
      myData: {},
      searchLine: {},
      selectedStateIds: [],
      defaultStatus: {},
      airedStatus: {},
      notAiredStatus: {},
      myDate: this.date,
      diff: 0,
      selectedState: {},
      selectedStation: {},
      statusList: [],
      controlsRight: false
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getRawKey(brk)
     {
    	return brk.gridId+":"+brk.mediaId+":"+brk.breakNo+":"+brk.breakTypeId+":"+brk.date;
     },
    getRef(idx1,idx2) { return "SSL_"+idx1+"."+idx2;},
    findInLog(spot){ this.$emit('findInLog', spot);},
    getHeaderStyle() {
    	if ( this.selectedState )
    	{
    		return "background-color: "+this.getState().colour+";";
    	}
    	return "";
    },
	dateChanged(date) {
		this.$emit("dateChanged", this.dayId, date)
	},
	stationChanged(selectedStation) {
		this.$emit("stationChanged", this.dayId, selectedStation.id)
	},
	copyBreak( target, source, fromId, toId) {
		this.$emit( "moveBreak", "copy", target, source, fromId, toId);
	},
	runIBO( key) {
		this.$emit( "runIBO", key);
	},
	moveBreak( target, source, fromId, toId) {
		this.$emit( "moveBreak", "move", target, source, fromId, toId);
	},
	contextmenu(event, spot){
        this.$refs.menu.open(event, spot )
	},
	changeType(event, type, spot)
    {
        try {
        this.$refs.menu.close();
        } catch (e) { 
        //
        } 
        if ( spot.statusId !== type.id )
        {
     		var mySpots = new Array();
     		mySpots.push(spot);
     		this.changeTypePassThrough(mySpots, type); 	
     	}
     },
     changeAllType(event, type )
     {
        try {
        this.$refs.menu.close();
        } catch (e) { 
        //
        } 
     	var mySpots = new Array();
        for( let gridId in this.myData.gridAds )
        {
        	let grid = this.myData.gridAds[gridId];
        	for( let brkId in grid.breaks )
        	{
        		let brk = grid.breaks[brkId];
        		for( let spotId in brk.spots )
        		{
        			mySpots.push( { id: brk.spots[spotId].id });
        		}
        	}
        }
     	this.changeTypePassThrough(mySpots, type); 	
     },
     changeTypePassThrough(mySpots, type, line)
     {
     	this.$emit("setTypeForSpots", mySpots, type, line);	
     },
	closeCMN() { try{ this.$refs.menu.close() } catch(e) { /* */ }},
	toggleMarks(ctx){
		if ( Array.isArray(this.$refs[ctx.name]))
		{
			this.$refs[ctx.name][0].toggleMarks();
		}
		else
		{
			this.$refs[ctx.name].toggleMarks();
		}
	},
	openBookMan( spot ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
        openView( this.$router, this.$toast, 'bookMan?toOpen=Placement&list=true&id='+ spot.placementId);
    },  
	forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	printTime2( val) { return printTime2(val);},
	arrangeSpots( spots){ this.$emit("arrangeSpots", this.dayId, spots)},
	removeCopy( spots ) { this.$emit("removeCopy", spots); },
	setPrice( spots, price ) { this.$emit("setPrice", spots, price); },
	userUpdated( user) { this.user=user; this.$emit("userUpdated", user );  },
	moveSpots( dndType, spots, target, source, fromId, toId){ this.$emit("moveSpots", dndType, spots, target, source, fromId, toId)},
	deleteSpot( spots, fromId ) { this.$emit("deleteSpot", spots, fromId) },
	getSlotClass(grid, selectedPrograms) {
	    let spots = grid.breaks.find( b => b.spots && b.spots.length);
		if ( (!selectedPrograms || !selectedPrograms.length || selectedPrograms.includes(grid.detail.name)) && (spots || this.showEmptyBreaks))
		{
			return "transition slot";
		}
		return "transition invisible ";
	},
	getState()
	{
		return this.myData.statusList.find( p => p.shortname === this.selectedState);
	},
	getBreakCode(adSpace, brk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += " "+brk.shortname;
       
      	return code;
      },
	doFilterSpots( data)
    {
     
        for ( var iGrid in data.gridAds)
        {
        	
        	let grid = data.gridAds[iGrid];
        	if ( grid )
        	{
            	for ( var iBreak in grid.breaks)
            	{
            		let brk = grid.breaks[iBreak];
            		if ( brk )
        			{
        				let show = true;
        			    if ( this.selectedBreaks && this.selectedBreaks.length > 0 )
				     	{
				     		show = this.selectedBreaks.includes(brk.type.shortname)
				     	}
				     	brk.show = show;
				     	let tmp = [];
				     	if ( show )
				     	{
	        				tmp = brk.unfilteredSpots;
	        				if ( tmp )
	        				{
		        				if ( this.selectedPrograms && this.selectedPrograms.length > 0 )
						     	{
						     		tmp =  tmp.filter(spot => this.selectedPrograms.includes(spot.gridName) );
						     	}
						     	if ( this.selectedClients && this.selectedClients.length > 0 )
						     	{
						     		tmp =  tmp.filter(spot => this.selectedClients.includes(spot.clientName) );
						     	}
						     	if ( this.selectedProducts && this.selectedProducts.length > 0 )
						     	{
						     		tmp =  tmp.filter(spot => this.selectedProducts.includes(spot.productName) );
						     	}
						     	if ( this.selectedStateIds && this.selectedStateIds.length > 0 )
						     	{
						     		tmp =  tmp.filter(spot => this.selectedStateIds.includes(spot.statusId) );
						     	}
						     	if ( this.selectedCopies && this.selectedCopies.length > 0 )
						     	{
						     		tmp =  tmp.filter(spot => this.selectedCopies.includes(spot.copyName) );
						     	}
						    }
	                		
	                	}
	                	brk.spots = tmp;
            		}	
            	}
            }
        }
		
    },
    
    findSpot( data, line)
    {
     
        for ( var iGrid in data.gridAds)
        {
        	
        	let grid = data.gridAds[iGrid];
        	if ( grid )
        	{
            	for ( var iBreak in grid.breaks)
            	{
            		let brk = grid.breaks[iBreak];
            		if ( brk )
        			{
				     	let tmp = brk.unfilteredSpots;
        				if ( tmp )
        				{
	        				let spot =  tmp.find(s => line.reconcileString == s.id);
	        				if ( spot )
	        				{
	        					return spot;
	        				}
					    }
            		}	
            	}
            }
        }
		return null;
    },
    
	set( data)
	{
		if ( !Array.isArray( data))
		{
			this.myData = data;
			this.diff = data.gridAds.diff;
			this.doFilterSpots(this.myData)
			this.selectedState = this.stateName;
			this.statusList = new Array();
			this.selectedStation = {id: this.station.id, label: this.station.name}
			for ( var i in data.statusList )
			{
				this.statusList.push( data.statusList[i].shortname )
			}
		}
	},
	stateChanged()
	{
		this.$emit("changeState", this.myData.date, this.myData.media.id, this.getState())
	}
  },
  updated() {
    this.$nextTick(function () {
  	this.$refs.menu.open(event, {spot: {}, name: "" });
	this.closeCMN();
	});
  },
  created() { 
 
	this.set( this.data);
	this.airedStatus =this.bookingStatusList.find(p=>p.airedValue==='aired');
	this.notAiredStatus = this.bookingStatusList.find(p=>p.airedValue==='notAired');
	this.defaultStatus = this.bookingStatusList.find(p=>p.isDefault );
	this.$nextTick(function () {
  	this.$refs.menu.open(event, {spot: {}, name: "" });
	this.closeCMN();
	});
  },
  watch: {
    stateName: function() {
		this.set( this.data);
	},
	data: function() {
		this.set( this.data);
	},
	date: function() {
		this.set( this.data);
	},
	update: function() {
	    this.set( this.data);
	},
	selectedPrograms: function() {
		this.set( this.data);
	},
	selectedCopies: function() {
		this.set( this.data);
	},
	selectedBreaks: function() {
		this.set( this.data);
	},
	selectedStates: function() {
	    this.selectedStateIds = this.selectedStates.map( p=> p.id);
		this.set( this.data);
	},
	selectedClients: function() {
		this.set( this.data);
	},
	selectedProducts: function() {
		this.set( this.data);
	},
	lookForLine: function() {
	    this.$set(this, "searchLine", this.lookForLine);
	},
	updateLine: function() {
	    this.$set(this, "searchLine", this.updateLine);
	    if ( this.updateLine.reconcileString && this.airedStatus.id)
	    {
	    	let spot = this.findSpot( this.myData, this.updateLine );
	    	if ( spot )
	    	{
	    	    this.changeTypePassThrough([spot], this.airedStatus, this.updateLine);
	    	}
	    }
	},
	bookingStatusList: function() {
	    
		this.airedStatus =this.bookingStatusList.find(p=>p.airedValue==='aired');
		this.notAiredStatus = this.bookingStatusList.find(p=>p.airedValue==='notAired');
		this.defaultStatus = this.bookingStatusList.find(p=>p.isDefault );
	}
	
  },
 }
</script>

<style scoped>
.slot {
    margin-top: 0pt !important;
	box-shadow: 6pt 6pt 5pt 0 #bbb;
}
.invisible {
	visibility: hidden;
	position: absolute;
	color: transparent;
	background-color: transparent;
}
.transition {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.RDHeader {
    display: inline;
	width: 99% !important;
	font-size: 12px;
	height: 100% !important;
	font-weight: bold;
}
.RDHeaderText {
	padding-top: 3pt;
	padding-left: 2pt;
}
.RDProgramTitle {
    display: inline-flex;
    padding-left: 5pt;
	font-size: 14px;
	font-weight: bold;
	background-color: transparent;
}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #fff;
}
.RDProgramTime2 {
    display: inline-flex;
	font-size: 14px;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: transparent;
}
.RDProgram {
	font-size: 14px;
	font-weight: bold;
	background-color: transparent;
}
.RDBreakTitle {
	
	font-weight: bold;
	
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #aaa;
	font-weight: bold;
	background-color: transparent;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #aaa;
	font-weight: bold;
	background-color: transparent;
}
.RDBreak {
	font-size: 11px;
	font-weight: bold;
	background-color: transparent;
	border-top: 2px outset #ddd;
	text-align: left;
	padding-left: 6pt;
}
.RDOpenProgram {
    margin-top: 2pt;
    margin-bottom: 8pt;
    padding: 2px;
	border-top: 2px outset #ddd;
	border-left: 1px outset #888;
	border-bottom: 1px outset #ccc;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
   
    margin-right: 4pt;

}
.invers {
	padding-left: 8pt; 
	padding-right: 4pt;
	width: 100%; 
	height: 18pt;
	padding-top: 1pt;
	color: #000; 
	background-color: #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 78% !important;
  display: flex;
}
</style> 